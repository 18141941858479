import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useUsuarioLogado } from "App";
import { useResgateContext } from "App.routes";
import CpfTextField from "components/TextMaskCustom/CpfTextField";
import DataTextField from "components/TextMaskCustom/DataTextField";
import { Formik } from "formik";
import { CadastroStepper } from "pages/ResgatarGiftCard";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "services/axios-utils";
import * as Yup from "yup";
import TextFieldDefault from "../../components/TextFieldDefault/TextFieldDefault";
import CepTextField from "../../components/TextMaskCustom/CepTextField";
import PhoneTextField from "../../components/TextMaskCustom/PhoneTextField";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        marginTop: theme.spacing(3),
    },
    loadingCep: {
        height: "100%",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 26,
    },
}));

const validationSchema = Yup.object({
    cpf: Yup.string().required(),
    cep: Yup.string().required(),
    logradouro: Yup.string().required(),
    numero: Yup.string().required(),
    complemento: Yup.string(),
    bairro: Yup.string().required(),
    cidade: Yup.string().required(),
    uf: Yup.string().required(),
    telefone: Yup.string().required(),
    dataNascimento: Yup.string().required(),
});

export default function InformacoesAdicionaisUsuario() {
    const classes = useStyles();
    const history = useHistory();
    const usuario = useUsuarioLogado();
    const [loading, setLoading] = useState(false);
    const [pesquisandoCep, setPesquisandoCep] = useState(false);
    const [userDb, setUserDb] = useState();
    const [initialValues, setInitialValues] = useState({
        cpf: "",
        telefone: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        numero: "",
        complemento: "",
        dataNascimento: null,
        sexo: "",
    });
    const context = useResgateContext();
    const giftcard = context.giftCard;

    const carregarUsuario = useCallback(() => {
        axios.get(`/usuario`).then((result) => {
            const user = result.data;

            setUserDb(user);

            if (user.cpf) {
                const endereco = getEndereco(user);
                setInitialValues((initialValues) => ({ ...initialValues, ...user, ...endereco, cep: endereco.cep + "" }));
            }
        });
    }, []);

    useEffect(() => {
        if (!usuario) return;

        carregarUsuario();
    }, [usuario, carregarUsuario]);

    const getEndereco = (user) => {
        return user.endereco || (user.enderecos && user.enderecos[0]) || {};
    };

    const onChangeCep = (event, formik) => {
        formik.handleChange(event);

        const val = event.target.value;

        if (val.length === 9) {
            setPesquisandoCep(true);
            axios
                .get(`/cep/${val.replace("-", "")}`)
                .then((result) => {
                    const endereco = result.data;

                    if (endereco.logradouro) {
                        formik.setFieldValue("logradouro", endereco.tipoLogradouro + " " + endereco.logradouro);
                        formik.setFieldValue("bairro", endereco.bairro);
                        formik.setFieldValue("cidade", endereco.cidade);
                        formik.setFieldValue("uf", endereco.uf);
                    }
                })
                .catch((err) => {
                    toast.error("Erro ao pesquisar CEP. Por favor, informe manualmente.");
                    throw err;
                })
                .finally(() => {
                    setPesquisandoCep(false);
                });
        }
    };

    const onSubmit = (values) => {
        setLoading(true);

        const user = {
            login: usuario.email,
            email: usuario.email,
            nome: usuario.nome,
            cpf: values.cpf,
            telefone: values.telefone,
            dataNascimento: values.dataNascimento,
            sexo: values.sexo,
            app: "PROTEJA_SEU_PET",
            endereco: {
                id: getEndereco(userDb).id,
                logradouro: values.logradouro,
                numero: values.numero,
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                uf: values.uf,
                cep: values.cep,
            },
        };

        axios
            .put(`/usuario/`, user)
            .then((result) => {
                if (giftcard) {
                    history.push("/cadastrar-pet");
                } else {
                    setUserDb(result.data);
                    setLoading(false);
                    toast.success("Informações atualizadas.");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.code === 400) {
                    toast.error(err.message);
                } else {
                    toast.error("Ocorreu um erro ao gravar informacoes.");
                    throw err;
                }
            });
    };

    if (!userDb)
        return (
            <Container maxWidth="md">
                {giftcard && <CadastroStepper activeStep={1} />}
                <div className={classes.paper}>
                    <CircularProgress />
                </div>
            </Container>
        );

    const usuarioNovo = !userDb?.cpf;

    return (
        <Container component="main" maxWidth="md">
            {giftcard && <CadastroStepper activeStep={1} />}
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountBoxIcon />
                </Avatar>
                <Typography variant="h4">Informações adicionais</Typography>
                <Typography variant="body1" color="textSecondary">
                    {usuarioNovo
                        ? "Precisamos de mais algumas informações para completar seu cadastro"
                        : "Verifique se as informações de cadastro estão atualizadas"}
                </Typography>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                >
                    {(formik) => (
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="textSecondary">
                                        {usuario.nome} ({usuario.email})
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={6}>
                                    <CpfTextField label="CPF" name="cpf" />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <PhoneTextField label="Telefone" name="telefone" />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <DataTextField label="Data de Nascimento" name="dataNascimento" required={true} 
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("dataNascimento", newValue);
                                    }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Gênero</InputLabel>
                                        <Select
                                            label="Gênero"
                                            value={formik.values.sexo || ""}
                                            onChange={(ev) => {
                                                formik.setFieldValue("sexo", ev.target.value);
                                            }}
                                        >
                                            <MenuItem value={"F"}>Feminino</MenuItem>
                                            <MenuItem value={"M"}>Masculino</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Endereço</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CepTextField
                                                disabled={pesquisandoCep}
                                                fullWidth={false}
                                                label="CEP"
                                                name="cep"
                                                onChange={(event) => onChangeCep(event, formik)}
                                            />
                                            {pesquisandoCep && (
                                                <span className={classes.loadingCep}>
                                                    <CircularProgress size={26} />
                                                </span>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldDefault disabled={pesquisandoCep} label="Endereço" name="logradouro" />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextFieldDefault disabled={pesquisandoCep} label="Número" name="numero" />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <TextFieldDefault disabled={pesquisandoCep} label="Complemento" name="complemento" />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextFieldDefault disabled={pesquisandoCep} label="Bairro" name="bairro" />
                                        </Grid>
                                        <Grid item xs={8} sm={5}>
                                            <TextFieldDefault disabled={pesquisandoCep} label="Cidade" name="cidade" />
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <Autocomplete
                                                name="uf"
                                                options={Object.keys(Estados)}
                                                disabled={pesquisandoCep}
                                                value={formik.values.uf || null}
                                                onChange={(event, newValue) => {
                                                    formik.setFieldValue("uf", newValue || "");
                                                }}
                                                getOptionLabel={(op) => {
                                                    return Estados[op];
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Estado"
                                                        name="uf"
                                                        variant="outlined"
                                                        error={formik.touched.uf && Boolean(formik.errors.uf)}
                                                        helperText={formik.touched.uf && formik.errors.uf && formik.errors.uf}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={2} justify="space-between" alignItems="center">
                                {giftcard && (
                                    <Grid item>
                                        <Button component={RouterLink} to="/resgatar-gift-card" variant="outlined" color="primary">
                                            Voltar
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button type="submit" disabled={loading} variant="contained" color="primary">
                                        {giftcard ? "Continuar" : "Salvar"}
                                        {loading && (
                                            <span className={classes.loadingCep}>
                                                <CircularProgress size={24} />
                                            </span>
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
        </Container>
    );
}

const Estados = {
    AC: "Acre",
    AL: "Alagoas",
    AP: "Amapá",
    AM: "Amazonas",
    BA: "Bahia",
    CE: "Ceará",
    DF: "Distrito Federal",
    ES: "Espírito Santo",
    GO: "Goías",
    MA: "Maranhão",
    MT: "Mato Grosso",
    MS: "Mato Grosso do Sul",
    MG: "Minas Gerais",
    PA: "Pará",
    PB: "Paraíba",
    PR: "Paraná",
    PE: "Pernambuco",
    PI: "Piauí",
    RJ: "Rio de Janeiro",
    RN: "Rio Grande do Norte",
    RS: "Rio Grande do Sul",
    RO: "Rondônia",
    RR: "Roraíma",
    SC: "Santa Catarina",
    SP: "São Paulo",
    SE: "Sergipe",
    TO: "Tocantins",
};
